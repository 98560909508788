<template>
  <validation-observer ref="department_create_form" #default="{ invalid }">
    <b-form @submit.prevent="handleCreateClick" class="position-relative w-100">
      <b-form-group label="Name" class="w-100">
        <template v-slot:label
          >Name <span class="text-danger">*</span>
        </template>
        <validation-provider
          vid="title"
          #default="{ errors }"
          name="Name"
          rules="required"
        >
          <b-form-textarea
            rows="2"
            v-model="formData.title"
            id="Name"
            :state="errors.length > 0 ? false : null"
          ></b-form-textarea>

          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group label="Description" class="w-100">
        <validation-provider
          vid="description"
          #default="{ errors }"
          name="Description"
        >
          <b-form-textarea
            rows="4"
            v-model="formData.description"
            id="description"
            :state="errors.length > 0 ? false : null"
          ></b-form-textarea>

          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button type="submit" variant="success" :disabled="invalid">
          <b-spinner v-if="isLoading" label="Loading..." small class="mr-50" />
          <feather-icon v-else icon="CheckIcon" class="mr-50" />
          <span class="align-middle">Create</span></b-button
        >
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormSelect,
  BFormTextarea,
  BBadge,
  VBHover,
  VBTooltip,
  BOverlay,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";
import { required, min } from "@validations";
import ResponseMixins from "@/mixins/ResponseMixins";
import DepartmentMixins from "@/mixins/DepartmentMixins";
export default {
  mixins: [ResponseMixins, DepartmentMixins],
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormSelect,
    BFormTextarea,
    BBadge,
    VBHover,
    VBTooltip,
    BOverlay,
    BSpinner,
    BFormCheckbox,
  },

  props: {
    closeAction: {
      type: Function,
      required: true,
    },

    reloadParent: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      formData: {
        title: null,
        description: null,
      },
    };
  },

  methods: {
    handleCreateClick() {
      this.addNewDepartmentAndSetData(this.formData);
    },

    addNewDepartmentAndSetData(data) {
      this.isLoading = true;
      this.addNewDepartment(data)
        .then((res) => {
          console.log(res);
          this.handleResponse(res);
          this.reloadParent();
          this.closeAction();
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
          if (err.response) {
            if (err.response.status === 422) {
              this.$refs.department_create_form.setErrors(err.response.data.errors);
            }
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style></style>
