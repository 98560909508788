<template>
  <div class="w-100 h-auto">
    <b-sidebar
      id="sidebar-department-details"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      lazy
      width="600px"
    >
      <department-details-sidebar
        :reloadParent="load"
        :departmentId="selectedDepartmentId"
        :closeAction="closeDepartmentDetailsSidebar"
      />
    </b-sidebar>

    <b-modal ref="new-department-modal" hide-footer title="New Department">
      <new-department-form
        :closeAction="hideNewDepartmentModal"
        :reloadParent="load"
      />
    </b-modal>

    <b-modal ref="edit-department-modal" hide-footer title="Edit Department">
      <edit-department-form
        :departmentId="selectedDepartmentId"
        :closeAction="hideEditDepartmentModal"
        :reloadParent="load"
      />
    </b-modal>

    <div class="card">
      <div class="card-header pb-75" style="padding-right: 0">
        <div class="w-100 row justify-content-between align-items-center">
          <div
            class="d-flex flex-column align-items-start justify-content-start col-md-6 col-sm-12"
          >
            <h3 class="mb-25 font-weight-bolder">Departments</h3>
            <p class="mb-50">Manage all departments.</p>
          </div>
          <div class="col-md-6 col-sm-12">
            <div
              class="w-100 d-flex align-items-center justify-content-md-end justify-content-sm-end"
            >
              <b-button
                size="sm"
                variant="success"
                @click="handleNewDepartmentClick"
              >
                <feather-icon icon="PlusIcon" class="mr-50" /><span
                  class="align-middle"
                  >New Department</span
                >
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="pb-25 pt-0 w-100">
        <div class="app-fixed-search">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                v-if="filters.search == null || filters.search == ''"
                icon="SearchIcon"
                class="text-muted"
              />
              <feather-icon
                @click="
                  () => {
                    filters.search = null;
                  }
                "
                v-else
                icon="XIcon"
                class="text-danger cursor-pointer"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filters.search"
              placeholder="Search name..."
              debounce="500"
              size="md"
            />
          </b-input-group>
        </div>
      </div>
      <div class="position-relative table-responsive">
        <b-overlay
          :show="showOverlay"
          opacity="0.17"
          blur="1rem"
          rounded="md"
          variant="secondary"
        >
          <table role="table" class="table table-hover">
            <thead role="rowgroup">
              <tr role="row">
                <th scope="col"></th>
                <th scope="col">Name</th>
                <th scope="col">Description</th>
                <th scope="col" class="text-center">Actions</th>
              </tr>
            </thead>
            <tbody role="rowgroup">
              <template v-if="departments.length > 0">
                <tr
                  role="row"
                  v-for="(dep, index) in departments"
                  :key="dep._id"
                  @click="
                    () => {
                      handleDepartmentClick(dep._id);
                    }
                  "
                >
                  <td scope="row">
                    {{ index + 1 + (filters.page - 1) * 10 }}
                  </td>

                  <td role="cell" style="max-width: 20vw">
                    <span class="font-weight-bold">{{ dep.title }}</span>
                  </td>

                  <td role="cell">
                    {{ dep.description }}
                  </td>

                  <td role="cell">
                    <div
                      class="w-100 d-flex align-items-center justify-content-center"
                    >
                      <b-button
                        variant="flat-info"
                        class="btn-icon rounded-circle"
                        @click.stop="
                          () => {
                            handleEditClick(dep._id);
                          }
                        "
                      >
                        <feather-icon icon="EditIcon" />
                      </b-button>

                      <b-button
                        variant="flat-danger"
                        class="btn-icon rounded-circle"
                        @click.stop="
                          () => {
                            handleDeleteClick(dep._id);
                          }
                        "
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <empty-table-section title="Departments Empty">
                  <template #content>
                    <p class="font-weight-bold text-center">
                      No departments have been found.
                    </p>
                  </template>
                </empty-table-section>
              </template>
            </tbody>
          </table>
        </b-overlay>
      </div>
      <div class="card-body py-50" v-if="departments && departments.length > 0">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div>
            <p class="mb-0">
              Showing {{ pagination.from }}-{{ pagination.to }} out of
              {{ pagination.totalRisks }}
            </p>
          </div>
          <b-pagination
            v-model="filters.page"
            :per-page="pagination.perPage"
            :total-rows="pagination.rows"
            aria-controls="my-table"
            class="mb-0"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";

import {
  BPagination,
  BPaginationNav,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormInput,
  BBadge,
  BFormSelect,
  BInputGroupPrepend,
  BInputGroup,
  VBTooltip,
  BSidebar,
  BOverlay,
} from "bootstrap-vue";
import EmptyTableSection from "@/components/EmptyTableSection.vue";
import ResponseMixins from "@/mixins/ResponseMixins";
import DepartmentMixins from "@/mixins/DepartmentMixins";
import DepartmentDetailsSidebar from "./DepartmentDetailsSidebar.vue";
import NewDepartmentForm from "./NewDepartmentForm.vue";
import EditDepartmentForm from "./EditDepartmentForm.vue";

export default {
  mixins: [ResponseMixins, DepartmentMixins],
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BForm,
    BFormInput,
    BBadge,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    EmptyTableSection,
    BSidebar,
    BOverlay,
    DepartmentDetailsSidebar,
    NewDepartmentForm,
    EditDepartmentForm,
  },
  data() {
    return {
      pagination_pos: "center",

      showOverlay: false,
      pagination: {
        perPage: 10,
        from: 0,
        to: 0,
        totalRisks: null,
        rows: 0,
      },

      filters: {
        search: null,
        page: 1,
      },

      departments: [],
      selectedDepartmentId: null,
    };
  },
  watch: {
    filters: {
      handler: function (newValues) {
        this.getDepartmentsAndSetData(newValues);
      },
      deep: true,
    },
  },
  mounted: function () {
    this.load();
  },
  methods: {
    load: function () {
      this.getDepartmentsAndSetData(this.filters);
    },

    getDepartmentsAndSetData(params) {
      this.showOverlay = true;
      this.getDepartments(params)
        .then((res) => {
          console.log(res);
          this.departments = res.data.data.data;
          this.pagination.rows =
            res.data.data.last_page * res.data.data.per_page;
          this.pagination.perPage = res.data.data.per_page;
          this.pagination.from = res.data.data.from;
          this.pagination.to = res.data.data.to;
          this.pagination.totalRisks = res.data.data.total;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleEditClick(id) {
      this.selectedDepartmentId = id
      this.showEditDepartmentModal()
    },

    showEditDepartmentModal() {
      this.$refs["edit-department-modal"].show();
    },
    hideEditDepartmentModal() {
      this.$refs["edit-department-modal"].hide();
    },

    handleDeleteClick(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to delete this department.",
          {
            title: "Warning",
            size: "sm",
            okVariant: "danger",
            okTitle: "Delete",
            cancelTitle: "Cancel",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
            headerBgVariant: "light-warning",
          }
        )
        .then((value) => {
          if (value) {
            this.deleteDepartmentAndSetData(id);
          }
        });
    },

    deleteDepartmentAndSetData(id) {
      this.showOverlay = true;
      this.deleteDepartment(id)
        .then((res) => {
          this.handleResponse(res);
          console.log(res);
          this.load();
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleNewDepartmentClick() {
      this.showNewDepartmentModal();
    },

    showNewDepartmentModal() {
      this.$refs["new-department-modal"].show();
    },
    hideNewDepartmentModal() {
      this.$refs["new-department-modal"].hide();
    },

    handleDepartmentClick(id) {
      this.selectedDepartmentId = id;
      this.openDepartmentDetailsSidebar();
    },

    closeDepartmentDetailsSidebar() {
      this.$root.$emit("bv::toggle::collapse", "sidebar-department-details");
    },
    openDepartmentDetailsSidebar() {
      this.$root.$emit("bv::toggle::collapse", "sidebar-department-details");
    },
  },
};
</script>

<style scoped>
.table th {
  text-transform: none;
}
</style>

<style lang="scss">
.app-fixed-search {
  background-color: transparent;

  .input-group:focus-within {
    box-shadow: none;
  }

  input,
  .input-group-text {
    border: 0;
    background-color: transparent;
  }
}
</style>
