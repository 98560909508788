var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sidebar-template',{attrs:{"title":"Department Details","closeAction":_vm.closeAction},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('b-overlay',{staticClass:"h-100",attrs:{"opacity":"0.17","blur":"1rem","show":_vm.showOverlay,"rounded":"md","variant":"secondary"}},[_c('div',{staticClass:"w-100 h-100 d-flex flex-column align-items-start justify-content-start"},[_c('div',{staticClass:"d-flex flex-column align-items-start justify-content-start mb-1 w-100"},[_c('h6',{staticClass:"text-primary"},[_vm._v("Name")]),_c('div',{staticClass:"rounded border d-flex align-items-start justify-content-start w-100 p-1"},[_c('div',{staticClass:"d-flex flex-column align-items-start justify-content-start w-100"},[_c('p',{staticClass:"font-weight-bold mb-0"},[_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.department.title))])]),(
                  _vm.department.description &&
                  _vm.department.description != null &&
                  _vm.department.description != ''
                )?_c('small',{staticClass:"mt-25"},[_vm._v(" "+_vm._s(_vm.department.description)+" ")]):_vm._e()])])]),_c('div',{staticClass:"d-flex flex-column align-items-start justify-content-start mt-1 w-100"},[_c('h6',{staticClass:"text-primary mb-25"},[_vm._v("Users")]),_c('table',{staticClass:"table table-small table-bordered",attrs:{"role":"table"}},[_c('thead',{attrs:{"role":"rowgroup"}},[_c('tr',{attrs:{"role":"row"}},[_c('th',{attrs:{"scope":"col"}}),_c('th',{attrs:{"scope":"col"}},[_vm._v("Name")])])]),_c('tbody',{attrs:{"role":"rowgroup"}},[(
                  _vm.department.users &&
                  _vm.department.users != null &&
                  _vm.department.users.length > 0
                )?_vm._l((_vm.department.users),function(usr,index){return _c('tr',{key:usr._id,attrs:{"role":"row"}},[_c('th',{attrs:{"scope":"row"}},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('td',{staticStyle:{"max-width":"16vw"},attrs:{"role":"cell"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(usr.firstname)+" "+_vm._s(usr.lastname))])])])}):[_c('empty-table-section',{attrs:{"title":"Users Empty"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',{staticClass:"font-weight-bold text-center"},[_vm._v(" No users have been found. ")])]},proxy:true}])})]],2)])])])])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }