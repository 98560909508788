export default {
  methods: {
    getDepartments(params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/departments`,
          params: params,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    getDepartmentDetails(departmentId) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/departments/${departmentId}`,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    addNewDepartment(data) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "POST",
          url: `${process.env.VUE_APP_BASEURL}/departments`,
          data: data,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    updateDepartment(departmentId, data) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "PUT",
          url: `${process.env.VUE_APP_BASEURL}/departments/${departmentId}`,
          data: data,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    deleteDepartment(departmentId) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "DELETE",
          url: `${process.env.VUE_APP_BASEURL}/departments/${departmentId}`,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },
  },
};
