<template>
  <sidebar-template title="Department Details" :closeAction="closeAction">
    <template #content>
      <b-overlay
        opacity="0.17"
        blur="1rem"
        :show="showOverlay"
        rounded="md"
        variant="secondary"
        class="h-100"
      >
        <div
          class="w-100 h-100 d-flex flex-column align-items-start justify-content-start"
        >
          <div
            class="d-flex flex-column align-items-start justify-content-start mb-1 w-100"
          >
            <h6 class="text-primary">Name</h6>
            <div
              class="rounded border d-flex align-items-start justify-content-start w-100 p-1"
            >
              <div
                class="d-flex flex-column align-items-start justify-content-start w-100"
              >
                <p class="font-weight-bold mb-0">
                  <span class="align-middle">{{ department.title }}</span>
                </p>
                <small
                  class="mt-25"
                  v-if="
                    department.description &&
                    department.description != null &&
                    department.description != ''
                  "
                >
                  {{ department.description }}
                </small>
              </div>
            </div>
          </div>
          <div
            class="d-flex flex-column align-items-start justify-content-start mt-1 w-100"
          >
            <h6 class="text-primary mb-25">Users</h6>
            <table role="table" class="table table-small table-bordered">
              <thead role="rowgroup">
                <tr role="row">
                  <th scope="col"></th>
                  <th scope="col">Name</th>
                </tr>
              </thead>
              <tbody role="rowgroup">
                <template
                  v-if="
                    department.users &&
                    department.users != null &&
                    department.users.length > 0
                  "
                >
                  <tr
                    role="row"
                    v-for="(usr, index) in department.users"
                    :key="usr._id"
                  >
                    <th scope="row">
                      {{ index + 1 }}
                    </th>

                    <td role="cell" style="max-width: 16vw">
                      <span class="font-weight-bold"
                        >{{ usr.firstname }} {{ usr.lastname }}</span
                      >
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <empty-table-section title="Users Empty">
                    <template #content>
                      <p class="font-weight-bold text-center">
                        No users have been found.
                      </p>
                    </template>
                  </empty-table-section>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </b-overlay>
    </template>
  </sidebar-template>
</template>

<script>
import { BOverlay, BBadge, BButton } from "bootstrap-vue";
import SidebarTemplate from "../../components/SidebarTemplate.vue";
import DepartmentMixins from "@/mixins/DepartmentMixins";
import ResponseMixins from "@/mixins/ResponseMixins";
import EmptyTableSection from "@/components/EmptyTableSection.vue";
export default {
  mixins: [DepartmentMixins, ResponseMixins],
  components: {
    SidebarTemplate,
    BOverlay,
    BBadge,
    BButton,
    EmptyTableSection,
  },

  data() {
    return {
      showOverlay: false,
      department: null,
    };
  },

  mounted() {
    this.load();
  },

  props: {
    departmentId: {
      type: String,
      required: true,
    },
    closeAction: {
      type: Function,
      required: true,
    },

    reloadParent: {
      type: Function,
      required: true,
    },
  },

  methods: {
    load() {
      this.getDepartmentDetailsAndSetData(this.departmentId);
    },

    getDepartmentDetailsAndSetData(id) {
      this.showOverlay = true;
      this.getDepartmentDetails(id)
        .then((res) => {
          console.log("Department Details", res);
          this.department = res.data.data;
        })

        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
  },
};
</script>
