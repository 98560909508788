var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 h-auto"},[_c('b-sidebar',{attrs:{"id":"sidebar-department-details","sidebar-class":"sidebar-lg","bg-variant":"white","backdrop":"","right":"","no-header":"","shadow":"","lazy":"","width":"600px"}},[_c('department-details-sidebar',{attrs:{"reloadParent":_vm.load,"departmentId":_vm.selectedDepartmentId,"closeAction":_vm.closeDepartmentDetailsSidebar}})],1),_c('b-modal',{ref:"new-department-modal",attrs:{"hide-footer":"","title":"New Department"}},[_c('new-department-form',{attrs:{"closeAction":_vm.hideNewDepartmentModal,"reloadParent":_vm.load}})],1),_c('b-modal',{ref:"edit-department-modal",attrs:{"hide-footer":"","title":"Edit Department"}},[_c('edit-department-form',{attrs:{"departmentId":_vm.selectedDepartmentId,"closeAction":_vm.hideEditDepartmentModal,"reloadParent":_vm.load}})],1),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header pb-75",staticStyle:{"padding-right":"0"}},[_c('div',{staticClass:"w-100 row justify-content-between align-items-center"},[_vm._m(0),_c('div',{staticClass:"col-md-6 col-sm-12"},[_c('div',{staticClass:"w-100 d-flex align-items-center justify-content-md-end justify-content-sm-end"},[_c('b-button',{attrs:{"size":"sm","variant":"success"},on:{"click":_vm.handleNewDepartmentClick}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("New Department")])],1)],1)])])]),_c('div',{staticClass:"pb-25 pt-0 w-100"},[_c('div',{staticClass:"app-fixed-search"},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[(_vm.filters.search == null || _vm.filters.search == '')?_c('feather-icon',{staticClass:"text-muted",attrs:{"icon":"SearchIcon"}}):_c('feather-icon',{staticClass:"text-danger cursor-pointer",attrs:{"icon":"XIcon"},on:{"click":function () {
                  _vm.filters.search = null;
                }}})],1),_c('b-form-input',{attrs:{"placeholder":"Search name...","debounce":"500","size":"md"},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1)],1)]),_c('div',{staticClass:"position-relative table-responsive"},[_c('b-overlay',{attrs:{"show":_vm.showOverlay,"opacity":"0.17","blur":"1rem","rounded":"md","variant":"secondary"}},[_c('table',{staticClass:"table table-hover",attrs:{"role":"table"}},[_c('thead',{attrs:{"role":"rowgroup"}},[_c('tr',{attrs:{"role":"row"}},[_c('th',{attrs:{"scope":"col"}}),_c('th',{attrs:{"scope":"col"}},[_vm._v("Name")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Description")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("Actions")])])]),_c('tbody',{attrs:{"role":"rowgroup"}},[(_vm.departments.length > 0)?_vm._l((_vm.departments),function(dep,index){return _c('tr',{key:dep._id,attrs:{"role":"row"},on:{"click":function () {
                    _vm.handleDepartmentClick(dep._id);
                  }}},[_c('td',{attrs:{"scope":"row"}},[_vm._v(" "+_vm._s(index + 1 + (_vm.filters.page - 1) * 10)+" ")]),_c('td',{staticStyle:{"max-width":"20vw"},attrs:{"role":"cell"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(dep.title))])]),_c('td',{attrs:{"role":"cell"}},[_vm._v(" "+_vm._s(dep.description)+" ")]),_c('td',{attrs:{"role":"cell"}},[_c('div',{staticClass:"w-100 d-flex align-items-center justify-content-center"},[_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-info"},on:{"click":function($event){$event.stopPropagation();return (function () {
                          _vm.handleEditClick(dep._id);
                        }).apply(null, arguments)}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}})],1),_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-danger"},on:{"click":function($event){$event.stopPropagation();return (function () {
                          _vm.handleDeleteClick(dep._id);
                        }).apply(null, arguments)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}})],1)],1)])])}):[_c('empty-table-section',{attrs:{"title":"Departments Empty"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',{staticClass:"font-weight-bold text-center"},[_vm._v(" No departments have been found. ")])]},proxy:true}])})]],2)])])],1),(_vm.departments && _vm.departments.length > 0)?_c('div',{staticClass:"card-body py-50"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between w-100"},[_c('div',[_c('p',{staticClass:"mb-0"},[_vm._v(" Showing "+_vm._s(_vm.pagination.from)+"-"+_vm._s(_vm.pagination.to)+" out of "+_vm._s(_vm.pagination.totalRisks)+" ")])]),_c('b-pagination',{staticClass:"mb-0",attrs:{"per-page":_vm.pagination.perPage,"total-rows":_vm.pagination.rows,"aria-controls":"my-table"},model:{value:(_vm.filters.page),callback:function ($$v) {_vm.$set(_vm.filters, "page", $$v)},expression:"filters.page"}})],1)]):_vm._e()])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column align-items-start justify-content-start col-md-6 col-sm-12"},[_c('h3',{staticClass:"mb-25 font-weight-bolder"},[_vm._v("Departments")]),_c('p',{staticClass:"mb-50"},[_vm._v("Manage all departments.")])])}]

export { render, staticRenderFns }